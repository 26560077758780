import { useEffect, useState } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { v4 } from "uuid";
import { deepEqual } from "fast-equals";
import { Element, scroller } from "react-scroll";
import { websocketAddressV2 } from "utils/constants";
import { usePrevious } from "./hooks";
import {
  GiftSuggestionsWithId,
  ConfigMetaDataResponse,
  LikesDislikesDataResponse,
  ErrorResponse,
  QueryFields,
  Query,
  LoadingResponse,
} from "./types";
import {
  isGiftSuggestionsArray,
  isConfigMetaData,
  isLikesDislikesData,
  isErrorData,
  isLoadingResponseData,
} from "./helper";
import { MetaTitle } from "components/MetaTitle";
import { Header } from "components/Header";
import { Loader } from "components/Loader";
import { Search } from "./Search";
import { Results } from "./Results";
import { Container } from "./style";
import { Separator } from "components/Separator";

function V3() {
  // const { user } = useAuth0();
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketAddressV2,
    {
      share: false,
      shouldReconnect: () => true,
    }
  );
  const prevLastJsonMessage = usePrevious(lastJsonMessage);
  // Disable login screen for now.
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [loadingData, setLoadingData] = useState<LoadingResponse | null>(null);
  const [giftSuggestions, setGiftSuggestions] = useState<GiftSuggestionsWithId>(
    []
  );
  const [configMetaData, setConfigMetaData] =
    useState<ConfigMetaDataResponse | null>(null);
  const [likesDislikesData, setLikesDislikesData] =
    useState<LikesDislikesDataResponse | null>(null);
  const [errorData, setErrorData] = useState<ErrorResponse | null>(null);
  const prevErrorData = usePrevious(errorData);
  const prevIsLoadingSuggestions = usePrevious(isLoadingSuggestions);

  // const userId = user?.sub;

  // Retrieve or generate a unique device_id
  const [deviceId, setDeviceId] = useState<string>(() => {
    const storedDeviceId = localStorage.getItem("device_id");
    if (storedDeviceId) {
      return storedDeviceId;
    } else {
      const newDeviceId = v4();
      localStorage.setItem("device_id", newDeviceId);
      setDeviceId(newDeviceId);
      return newDeviceId;
    }
  });

  useEffect(() => {
    // console.log("readyState:");
    // console.log(readyState);

    if (readyState === ReadyState.OPEN) {
      // Get initial data to power the form fields on ready
      console.log("SEND:");
      console.log("sendJsonMessage({ config: true });");
      sendJsonMessage({
        config: true,
      });
    }
  }, [readyState, sendJsonMessage]);

  useEffect(() => {
    console.log("RECEIVE:");
    console.log(lastJsonMessage);

    if (!deepEqual(lastJsonMessage, prevLastJsonMessage)) {
      if (isConfigMetaData(lastJsonMessage)) {
        setConfigMetaData(lastJsonMessage);
      }

      if (isLikesDislikesData(lastJsonMessage)) {
        setLikesDislikesData(lastJsonMessage);
      }

      if (isErrorData(lastJsonMessage)) {
        setErrorData(lastJsonMessage);

        // Reset loading suggestions state if true upon receiving an error
        if (prevIsLoadingSuggestions === true) {
          setIsLoadingSuggestions(false);
        }
      }

      if (isLoadingResponseData(lastJsonMessage)) {
        setLoadingData(lastJsonMessage);
      }

      if (isGiftSuggestionsArray(lastJsonMessage)) {
        console.log("isGiftSuggestionsArray true");
        setIsLoadingSuggestions(false);

        const mappedGiftSuggestions: GiftSuggestionsWithId =
          lastJsonMessage.results.map((giftSuggestion) => ({
            ...(giftSuggestion.group && {
              group: giftSuggestion.group,
            }),
            results: giftSuggestion.results
              ? giftSuggestion.results.map((result) => ({
                  ...result,
                  id: v4(),
                }))
              : [],
            id: v4(),
          }));
        setGiftSuggestions(mappedGiftSuggestions);

        if (prevIsLoadingSuggestions) {
          scroller.scrollTo("results", {
            duration: 500,
            smooth: true,
            offset: -100,
          });
        }

        // Reset error data if was previously set
        if (prevErrorData !== null && prevErrorData !== undefined) {
          setErrorData(null);
        }

        // Reset previous loading data
        setLoadingData(null);
      }
    }
  }, [
    lastJsonMessage,
    prevLastJsonMessage,
    prevErrorData,
    prevIsLoadingSuggestions,
  ]);

  function getQueryData({ queryFields }: { queryFields: QueryFields }): Query {
    const { budget, who, about, location, age, likes, dislikes } = queryFields;
    const query = {
      query: {
        ...(budget.length && {
          budget: parseInt(budget, 10),
        }),
        ...(who.length && {
          who,
        }),
        ...(about.length && {
          about,
        }),
        ...(location.length && {
          location,
        }),
        ...(age.length && {
          age,
        }),
        ...(likes.length && {
          likes,
        }),
        ...(dislikes.length && {
          dislikes,
        }),
        device_id: deviceId,
      },
    };

    return query;
  }

  function getLikesDislikes({
    age,
    gender,
    location,
  }: {
    age: string;
    gender: string;
    location: string;
  }) {
    const queryData = {
      data: {
        age,
        gender,
        location,
        device_id: deviceId,
      },
    };
    console.log("SEND:");
    console.log(queryData);
    sendJsonMessage(queryData);
  }

  function getGiftSuggestions({ queryFields }: { queryFields: QueryFields }) {
    const queryData = getQueryData({ queryFields });
    console.log("SEND:");
    console.log(queryData);
    sendJsonMessage(queryData);
    setIsLoadingSuggestions(true);
  }

  function sendGiftBuyNowNotification({
    title,
    link,
  }: {
    title: string;
    link: string;
  }) {
    const queryData = {
      buy: true,
      query: {
        title,
        link,
        device_id: deviceId,
      },
    };
    console.log("SEND:");
    console.log(queryData);
    sendJsonMessage(queryData);
  }

  return (
    <>
      <MetaTitle />
      <Container>
        {!configMetaData ? (
          <Loader variant="fixed" backdrop="transparent" />
        ) : null}
        <Header />
        <Search
          getGiftSuggestions={getGiftSuggestions}
          isLoadingSuggestions={isLoadingSuggestions}
          isWebsocketReady={readyState === ReadyState.OPEN}
          configMetaData={configMetaData}
          getLikesDislikes={getLikesDislikes}
          likesDislikesData={likesDislikesData}
          errorData={errorData}
          loadingData={loadingData}
        />
        <Separator />
        <Element name="results">
          <Results
            giftSuggestions={giftSuggestions}
            sendGiftBuyNowNotification={sendGiftBuyNowNotification}
          />
        </Element>
      </Container>
    </>
  );
}

export default V3;

/*
import { useEffect, useState } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { v4 } from "uuid";
import { deepEqual } from "fast-equals";
import { Element, scroller } from "react-scroll";
import { websocketAddressV2 } from "utils/constants";
import { usePrevious } from "./hooks";
import {
  GiftSuggestionsWithId,
  ConfigMetaDataResponse,
  LikesDislikesDataResponse,
  ErrorResponse,
  QueryFields,
  Query,
  LoadingResponse,
} from "./types";
import {
  isGiftSuggestionsArray,
  isConfigMetaData,
  isLikesDislikesData,
  isErrorData,
  isLoadingResponseData,
} from "./helper";
import { MetaTitle } from "components/MetaTitle";
import { Header } from "components/Header";
import { Loader } from "components/Loader";
import { Search } from "./Search";
import { Results } from "./Results";
import { Container } from "./style";

function V2() {
  // const { user } = useAuth0();
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketAddressV2,
    {
      share: false,
      shouldReconnect: () => true,
    }
  );
  const prevLastJsonMessage = usePrevious(lastJsonMessage);
  // Disable login screen for now.
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [loadingData, setLoadingData] = useState<LoadingResponse | null>(null);
  const [giftSuggestions, setGiftSuggestions] = useState<GiftSuggestionsWithId>(
    []
  );
  const [configMetaData, setConfigMetaData] =
    useState<ConfigMetaDataResponse | null>(null);
  const [likesDislikesData, setLikesDislikesData] =
    useState<LikesDislikesDataResponse | null>(null);
  const [errorData, setErrorData] = useState<ErrorResponse | null>(null);
  const prevErrorData = usePrevious(errorData);
  const prevIsLoadingSuggestions = usePrevious(isLoadingSuggestions);
  // const userId = user?.sub;

  useEffect(() => {
    // console.log("readyState:");
    // console.log(readyState);

    if (readyState === ReadyState.OPEN) {
      // Get initial data to power the form fields on ready
      console.log("SEND:");
      console.log("sendJsonMessage({ config: true });");
      sendJsonMessage({
        config: true,
      });
    }
  }, [readyState, sendJsonMessage]);

  useEffect(() => {
    console.log("RECEIVE:");
    console.log(lastJsonMessage);

    if (!deepEqual(lastJsonMessage, prevLastJsonMessage)) {
      if (isConfigMetaData(lastJsonMessage)) {
        setConfigMetaData(lastJsonMessage);
      }

      if (isLikesDislikesData(lastJsonMessage)) {
        setLikesDislikesData(lastJsonMessage);
      }

      if (isErrorData(lastJsonMessage)) {
        setErrorData(lastJsonMessage);

        // Reset loading suggestions state if true upon receiving an error
        if (prevIsLoadingSuggestions === true) {
          setIsLoadingSuggestions(false);
        }
      }

      if (isLoadingResponseData(lastJsonMessage)) {
        setLoadingData(lastJsonMessage);
      }

      if (isGiftSuggestionsArray(lastJsonMessage)) {
        console.log("isGiftSuggestionsArray true");
        setIsLoadingSuggestions(false);

        const mappedGiftSuggestions: GiftSuggestionsWithId =
          lastJsonMessage.results.map((giftSuggestion) => ({
            ...(giftSuggestion.group && {
              group: giftSuggestion.group,
            }),
            results: giftSuggestion.results
              ? giftSuggestion.results.map((result) => ({
                  ...result,
                  id: v4(),
                }))
              : [],
            id: v4(),
          }));
         setGiftSuggestions((prevSuggestions) => [
        ...prevSuggestions,
        ...mappedGiftSuggestions,
        //...prevSuggestions,
      ]);
        //setGiftSuggestions(mappedGiftSuggestions);

        if (prevIsLoadingSuggestions) {
          scroller.scrollTo("results", {
            duration: 500,
            smooth: true,
          });
        }

        // Reset error data if was previously set
        if (prevErrorData !== null && prevErrorData !== undefined) {
          setErrorData(null);
        }

        // Reset previous loading data
        // setLoadingData(null);
      }
    }
  }, [
    lastJsonMessage,
    prevLastJsonMessage,
    prevErrorData,
    prevIsLoadingSuggestions,
  ]);

  function getQueryData({ queryFields }: { queryFields: QueryFields }): Query {
    const { budget, who, about, location, age, likes, dislikes } = queryFields;
    const query = {
      query: {
        ...(budget.length && {
          budget: parseInt(budget, 10),
        }),
        ...(who.length && {
          who,
        }),
        ...(about.length && {
          about,
        }),
        ...(location.length && {
          location,
        }),
        ...(age.length && {
          age,
        }),
        ...(likes.length && {
          likes,
        }),
        ...(dislikes.length && {
          dislikes,
        }),
      },
    };

    return query;
  }

  function getLikesDislikes({
    age,
    gender,
    location,
  }: {
    age: string;
    gender: string;
    location: string;
  }) {
    const queryData = {
      data: {
        age,
        gender,
        location,
      },
    };
    console.log("SEND:");
    console.log(queryData);
    sendJsonMessage(queryData);
  }

  function getGiftSuggestions({ queryFields }: { queryFields: QueryFields }) {
    const queryData = getQueryData({ queryFields });
    console.log("SEND:");
    console.log(queryData);
    sendJsonMessage(queryData);
    setIsLoadingSuggestions(true);
  }

  function sendGiftBuyNowNotification({
    title,
    link,
  }: {
    title: string;
    link: string;
  }) {
    const queryData = {
      buy: true,
      query: {
        title,
        link,
      },
    };
    console.log("SEND:");
    console.log(queryData);
    sendJsonMessage(queryData);
  }

  return (
    <>
      <MetaTitle />
      <Container>
        {!configMetaData ? (
          <Loader variant="fixed" backdrop="transparent" />
        ) : null}
        <Header />
        <Search
          getGiftSuggestions={getGiftSuggestions}
          isLoadingSuggestions={isLoadingSuggestions}
          isWebsocketReady={readyState === ReadyState.OPEN}
          configMetaData={configMetaData}
          getLikesDislikes={getLikesDislikes}
          likesDislikesData={likesDislikesData}
          errorData={errorData}
          loadingData={loadingData}
        />
        <Element name="results">
          <Results
            giftSuggestions={giftSuggestions}
            sendGiftBuyNowNotification={sendGiftBuyNowNotification}
          />
        </Element>
      </Container>
    </>
  );
}

export default V2;
*/
