import { useState } from "react";
import { Modal, ModalInner } from "components/Modal"; // Adjust the import path as necessary/ Adjust the import path as necessary
import {
  MenuFooter,
  MenuItem,
  MenuList,
  MenuIconContainer,
  SecondaryMenuItem,
  SecondaryMenuList,
} from "./style";
import { MenuIcon } from "svg/SvgMenu";
import { SvgLogo } from "svg/SvgLogo";

export function Menu() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const menuItems = [
    {
      href: "https://www.givving.co/frequently-asked-questions",
      target: "_blank",
      rel: "noreferrer",
      text: "Frequently Asked Questions",
    },
    {
      href: "/page2",
      text: "Give Feedback",
    },
    {
      href: "https://www.givving.co/about",
      target: "_blank",
      rel: "noreferrer",
      text: "About",
    },
    {
      href: "https://www.givving.co/contact",
      target: "_blank",
      rel: "noreferrer",
      text: "Contact",
    },
  ];

  return (
    <>
      <MenuIconContainer onClick={toggleModal}>
        <MenuIcon />
      </MenuIconContainer>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          menu
          contentStyle={{ background: "rgb(22,22,24)", borderRadius: "0px" }}
          closePosition="left"
        >
          <ModalInner>
            <MenuList>
              {menuItems.map((item, index) => (
                <MenuItem key={index}>
                  <a href={item.href} target={item.target} rel={item.rel}>
                    {item.text}
                  </a>
                </MenuItem>
              ))}
            </MenuList>
            <SecondaryMenuList>
              <SecondaryMenuItem>
                <a
                  href="https://www.givving.co/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
              </SecondaryMenuItem>
              <SecondaryMenuItem>
                <a
                  href="https://www.givving.co/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </SecondaryMenuItem>
            </SecondaryMenuList>
            <MenuFooter>
              <SvgLogo />
              <p>
                This app is made by <span>Givving Limited</span>
              </p>
              <p>#HappyGivving</p>
            </MenuFooter>
          </ModalInner>
        </Modal>
      )}
    </>
  );
}
